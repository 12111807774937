'use client';
import React from "react";
import { useUserInformation } from "@/contexts/UserProvider";
import Link from 'next/link';
import { usePathname } from "next/navigation";

const UserAuth = () => {
    const { isLoggedIn } = useUserInformation();
    const path = usePathname();

    return (
        isLoggedIn ? (
            <Link rel="nofollow" href={`/api/auth/signout?callbackUrl=${path}`}>
                sign out
            </Link>
        ) : (
            <Link rel="nofollow" href={`/login?location=${path}`}>
                sign in
            </Link>
        )
    );
};

export default UserAuth;
